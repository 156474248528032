html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: Inter, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: FoxSansPro, sans-serif;
}
